import { useHash } from "@mantine/hooks";
import { Outlet } from "@remix-run/react";
import Footer from "~/components/home/Footer";
import Header from "~/components/home/Header";

import styles from "./_home.module.css";

export default function Layout() {
  const [hash, setHash] = useHash();

  return (
    <div className={styles.container}>
      <Header selectedLink={hash ?? ""} />
      <div className={styles.content}>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
