import { Container, Group, Text } from "@mantine/core";
import { Link } from "@remix-run/react";
import styles from "./Footer.module.css";

const links = [
  // { link: "/status", label: "Status" },
  { link: "/contactUs", label: "Contact Us" },
  { link: "/termsofservice", label: "Terms of Service" },
  { link: "/privacypolicy", label: "Privacy Policy" },
  // { link: "/cookiepolicy", label: "Cookie Policy" },
];

export default function Footer() {
  const year = new Date().getFullYear();

  const items = links.map((link) => (
    <Link className={styles.footerLink} key={link.label} to={link.link}>
      {link.label}
    </Link>
  ));

  return (
    <footer className={styles.wrapper}>
      <div className={styles.footer}>
        <Container className={styles.inner}>
          <Group className={styles.links}>{items}</Group>
          <Text className={styles.copyright} size="sm">
            © {year} Subroutine, Inc. All rights reserved.
          </Text>
        </Container>
      </div>
    </footer>
  );
}
