import { useHash } from "@mantine/hooks";
import { Link } from "@remix-run/react";
import { useMemo } from "react";

export type HashLinkProps = Parameters<typeof Link>[0];

export default function HashLink(props: HashLinkProps) {
  const [, setHash] = useHash();
  const { to, ...rest } = props;

  const isHash = useMemo(() => {
    return typeof to === "string" && to.indexOf("#") === 0;
  }, [to]);

  const onClick = useMemo(() => {
    return (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (props.onClick) {
        props.onClick(e);
      }
      if (isHash && !e.defaultPrevented) {
        e.preventDefault();
        setHash(to as string);
      }
    };
  }, [props.onClick, isHash]);

  return <Link {...rest} onClick={onClick} to={to} />;
}
