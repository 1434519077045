import {
  ActionIcon,
  Burger,
  Container,
  Divider,
  Drawer,
  Group,
  rem,
  ScrollArea,
  useMantineColorScheme,
} from "@mantine/core";

import { IconMoon, IconSun } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import type { MetaFunction } from "@remix-run/node";
import { useMemo } from "react";
import HashLink from "../common/HashLink";
import Wordmark from "../common/Wordmark";
import styles from "./Header.module.css";

export const meta: MetaFunction = () => {
  return [{ title: "Subroutine" }, { name: "description", content: "Welcome to Subroutine!" }];
};

type HeaderProps = {
  isHome?: boolean;
  selectedLink?: string;
};
export default function Header({ isHome, selectedLink }: HeaderProps) {
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const [opened, { toggle }] = useDisclosure(false);

  const links = useMemo(
    () => [
      { link: "/#features", label: "Features" },
      { link: "/#security", label: "Security" },
      { link: "/#plans", label: "Plans" },

      { link: "/contactUs", label: "Get Started" },
    ],
    [isHome]
  );

  const items = links.map((link, i) => (
    <HashLink
      key={i}
      to={link.link}
      className={link.link == selectedLink ? styles.linkActive : styles.link}
      onClick={(e) => {
        if (opened) {
          toggle();
        }
      }}
    >
      {link.label}
    </HashLink>
  ));

  return (
    <header className={styles.wrapper}>
      <Container size="md" className={styles.inner}>
        <Wordmark className={styles.wordmark} />
        <Group gap={5} visibleFrom="xs" className={styles.topNav}>
          {items}
          {colorScheme === "dark" ? (
            <ActionIcon
              color="gray"
              variant="transparent"
              aria-label="Switch to light mode"
              onClick={() => setColorScheme("light")}
            >
              <IconSun />
            </ActionIcon>
          ) : (
            <ActionIcon
              color="gray"
              variant="transparent"
              aria-label="Switch to dark mode"
              onClick={() => setColorScheme("dark")}
            >
              <IconMoon />
            </ActionIcon>
          )}
        </Group>

        <Burger aria-label="Toggle navigation" opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />

        <Drawer
          opened={opened}
          onClose={toggle}
          size="100%"
          padding="md"
          title="Navigation"
          hiddenFrom="sm"
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md" className={styles.drawer}>
            <Divider my="sm"></Divider>
            <div className={styles.drawerLinks}>
              {items.map((item, index) => (
                <div key={`drawer-link-${index}`}>{item}</div>
              ))}
            </div>
          </ScrollArea>
        </Drawer>
      </Container>
    </header>
  );
}
